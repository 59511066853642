import type { SearchResultsSectionData } from '@orus.eu/backend/src/services/search-service'
import { ensureError } from '@orus.eu/error'
import { useCrash } from '@orus.eu/pharaoh'
import { useSearch } from '@tanstack/react-router'
import { memo, useCallback, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { trpc, trpcReact } from '../../../../client'
import { useUpToDateStatus } from '../../../../lib/hooks/use-up-to-date-status'
import { GlobalLoadingState } from '../../../molecules/global-loading-state'
import { BackofficeSearchPageContent } from '../common/backoffice-search-page-content'

const PlatformSearchPage = memo(function PlatformSearchPage() {
  const searchIndexUpToDate = useUpToDateStatus(useCallback(() => trpc.search.isUpToDate.query(), []))
  switch (searchIndexUpToDate) {
    case 'checking':
      return <GlobalLoadingState />
    case 'up-to-date':
    case 'out-of-date':
      return <SearchBlockWrapper searchIndexUpToDate={searchIndexUpToDate} />
  }
})

export default PlatformSearchPage

const SearchBlockWrapper = memo<{ searchIndexUpToDate: 'up-to-date' | 'out-of-date' }>(function SearchBlock({
  searchIndexUpToDate,
}) {
  const crash = useCrash()
  const urlSearchParams = useSearch({ from: '/bak/search' })
  const [query, setQuery] = useState(urlSearchParams.q ?? '')

  /**
   * The query for which more results were requested, if any
   */
  const [requestMoreQuery, setRequestMoreQuery] = useState<string | undefined>()
  const trimmedQuery = query.trim()
  const exactMatchesQuery = trpcReact.search.searchExactMatchesV2.useQuery(trimmedQuery, {
    enabled: trimmedQuery !== '',
  })

  const [partialMatches, setPartialMatches] = useState<SearchResultsSectionData | undefined>()

  const partialMatchesRequested = requestMoreQuery && requestMoreQuery === query

  useEffect(() => {
    let cancelled = false
    if (!requestMoreQuery || requestMoreQuery !== query) {
      setPartialMatches(undefined)
      return
    }

    trpc.search.searchPartialMatches.query(requestMoreQuery).then(
      (matches) => {
        if (cancelled) return
        setPartialMatches(matches)
      },
      (err: unknown) => {
        if (cancelled) return
        crash(ensureError(err))
      },
    )

    return () => {
      cancelled = true
    }
  }, [requestMoreQuery, partialMatchesRequested, query, crash])

  const handleRequestMore = useCallback((requestMoreQuery: string) => {
    setRequestMoreQuery(requestMoreQuery)
  }, [])

  return (
    <>
      <Helmet>
        <link
          rel="search"
          type="application/opensearchdescription+xml"
          title="Orus"
          href="http://app.orus.eu/opensearch.xml"
        />
      </Helmet>
      <BackofficeSearchPageContent
        searchIndexUpToDate={searchIndexUpToDate}
        query={query}
        setQuery={setQuery}
        exactMatchesSearchOutput={trimmedQuery === '' ? 'no-query' : (exactMatchesQuery.data ?? 'loading')}
        partialMatchesRequested={partialMatchesRequested}
        partialMatches={partialMatches}
        handleRequestMore={handleRequestMore}
      />
    </>
  )
})
